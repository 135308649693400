<template>
   <div>
       <div class="nk-block" >
            <div id="kanban" class="nk-kanban">
                <div class="kanban-container" style="width: 100%;">
                    <div v-for="board in boards" v-bind:key="board.id" class="kanban-board" style="width: 100%; margin-left: 0px; margin-right: 0px; cursor:pointer;">
                        <header class="kanban-board-header kanban-light">
                            <div class="kanban-title-board">
                                <div class="kanban-title-content">
                                    <h6 class="title">{{board.status}}</h6>
                                    <span class="badge badge-pill badge-outline-light text-dark">{{board.tickets.length}}</span>
                                </div>
                                <div class="kanban-title-content">
                                    <div class="drodown">
                                         <a-dropdown>
                                            <div class="dropdown">
                                                <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" >
                                                <em class="icon ni ni-more-h"></em></a>
                                            </div>
                                         <a-menu slot="overlay">
                                            <a-menu-item>
                                                <a v-on:click="addNewTicket(board.id)" href="javascript:;"><i class="bg-success-dim ni ni-edit mr-1"></i> Add Ticket</a>
                                            </a-menu-item>
                                        </a-menu>
                                        </a-dropdown>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <ticketCard class="float-left" style="width: 320px; height:180px; margin-left: 10px;" @ticketSaved="ticketSaved"  @viewTicket="viewTicket" :ticket="ticket" v-for="(ticket, index) in board.tickets" v-bind:key="ticket.id">
                            <span>{{ index }}</span>
                        </ticketCard>
                        <footer>
                            <button v-on:click="addNewTicket(board.id)" class="kanban-add-task btn btn-block"><em class="icon ni ni-plus-sm"></em><span>Add another ticket</span></button>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import ticketCard from "@/components/desk/tickets/ticketcard";
export default {
  name: "Kanban",
  methods: {
     viewTicket (ticket) {
          this.$emit('viewTicket',ticket)
     },
    addNewTicket (statusId) {
        this.$emit('addNewTicket',statusId)
    },
    ticketSaved () {
        this.$emit('ticketSaved')
    },
    updateTicket(event,id) {
        if (event.added != null) {
            this.moveTicket(id, event.added.element.id)
        } 
    },
    moveTicket (ticketId, statusId) {
        let ticket  =  {id: ticketId, statusId: statusId}
        this.$http.post('/support/updateTicketStatus', ticket)
        .catch(() => {
          this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
        })
    },
  },
props: {
    boards: {
      type: Array,
      default: () => ({})
    },
  },
  components: {
    ticketCard,
  },
  computed: {
      // return tickets split down to boards.
  },
  data() {
    return {
        clickTimeoutId: null,
    };
  }
};
</script>
 
