<template>
  <div style="position:relative;" class="kanban-item unselectable"  :style="ticketStyle" v-on:click="openTicket(ticket)">
       <div :style="ticketTopBarStyle" :class="{'kanban-item-error' : alert}" style="padding: 0 10px 0 10px;height:30px;position:absolute;top:0;left:0;width:100%;" class="">
        <div class="float-left text-white">
           <div style="font-size:14px; padding-top:3px;">
            <strong>{{ days + ':' + hrs + ':' + mins + ':' + secs}}</strong>
          </div>
        </div>
        <div class="text-white" style="fontSize: 14px; padding-top:3px; margin-left: 40%;">
            <strong>TN{{ticket.id}}</strong>
        </div>
        <div class="float-right text-white" style="font-size: 18px;">
          <!-- <em v-on:click.stop="setStatus(ticket,3)" v-if="ticket.statusId != 3" class=" icon ni ni-play-circle-fill ticketbtn-start"></em> 
          <em v-on:click.stop="setStatus(ticket,4)" v-if="ticket.statusId  === 3" class="icon ni ni-pause-circle-fill ticketbtn-pause"></em> -->
          <a-dropdown>
            <em style="position: absolute; bottom: 7px; right: 8px;" class="icon ni ni-more-h ticketbtn-start"></em>
            <a-menu slot="overlay">
              <a-menu-item v-on:click="completeTicket()">
                <em class="icon ni ni-check-circle-fill pt-1 ticketbtn-start"></em>  Complete Ticket
              </a-menu-item>
              <a-menu-item @click="copyTicketRefToClipboard()">
                <em class="icon ni ni-edit pt-1 ticketbtn-start"></em>  Copy Ticket Ref
              </a-menu-item>
              <a-menu-item @click="copyTicketSubjectToClipboard()">
                <em class="icon ni ni-edit-fill pt-1 ticketbtn-start"></em>  Copy Ticket Subject
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        </div>
    <div class="kanban-item-text" style="font-size: 10px; padding-bottom:0px; padding-top: 18px;">
      <span class="float-right" v-for="i in activeTimers" :key="i.id">
          <a-popover placement="right">
              <template slot="content">
                  <div class="row">
                      <div class="col-12">
                          <span>{{ i.createdByUser }}</span>
                      </div>
                  </div>
              </template>
              <a><i class="ni ni-circle-fill pt-1" style="color: limegreen; font-size: 13px; z-index: 1;"/></a>
          </a-popover>
        </span>
        <div style="position:relative; font-weight:bold; font-size: 12px;">{{ticket.accountName}} : {{ticket.contactName || 'No Contact'}}
         <div style="position:absolute; right:-10px; top:-25px; font-size:20px;">

            
          </div>
        </div>
    </div>
    
    <div class="kanban-item-title">
      <h6 class="title">{{ticket.title}}</h6>
      <a-dropdown :trigger="['hover']" placement="bottomCenter">
          <avatar style="padding: 10px; margin:2px;" :src="ticket.assignedUserAvatarURI" :size="30" :rounded="true" v-if="ticket.assignedUserId" :username="ticket.assignedUser" />
          <em v-else style="font-size:20px; cursor:pointer; padding-left:20px;" class="icon ni ni-user-add"></em>
          <a-menu slot="overlay" class="menu">
              <a-menu-item v-for="u in users" :key="u.id" @click="updateTicket(u.id, 'assignedUserId')">
                <div class="user-card"><div class=""><avatar  :src="u.avatarURI" style="margin:2px;" :size="30" :rounded="true" :username="u.firstName + ' ' + u.lastName" /></div><div class="user-name"><span class="tb-lead">{{ u.firstName + ' ' + u.lastName }}</span></div></div>
              </a-menu-item>
          </a-menu>
      </a-dropdown>
    </div>
  
    <ul class="kanban-item-tags">
        <li v-for="tag in tags" v-bind:key="tag.id" v-bind:style="{backgroundColor: tag.color}"><span class="badge badge-info">{{tag}}</span></li>
    </ul>
    <div class="kanban-item-meta">
        <ul class="kanban-item-meta-list" style="padding-bottom: 5px;">
            <li>
              <avatar v-if="ticket.createdByUser" :src="ticket.createdByUserAvatarURI" :size="30" :rounded="true" :username="ticket.createdByUser" />
            </li>
        </ul>
        <ul class="kanban-item-meta-list">
            <li><em class="icon ni ni-clip"></em><span>{{ticket.attachmentCount}}</span></li>
            <li><em class="icon ni ni-comments"></em><span>{{ticket.commentCount}}</span></li>
        </ul>
        <ul style="font-size: 12px;">
          <li>
            <a-popover placement="right">
              <template slot="content">
                <p>This is the booked date.</p>
              </template>
              <div>
                <em v-if="ticket.scheduledDate" class="icon ni ni-calendar-alt-fill"></em>
                <span v-if="ticket.scheduledDate">{{$moment.utc(ticket.scheduledDate).local().format('H:mm DD/MM')}}</span>
              </div>
            </a-popover>
          </li>
          <li>
            <a-popover placement="right">
              <template slot="content">
                <p>This is the SLA due date.</p>
              </template>
              <div>
                <em v-if="ticket.slaDueDate" class="icon ni ni-calendar-booking-fill"></em>
                <span v-if="ticket.slaDueDate">{{$moment.utc(ticket.slaDueDate).local().format('H:mm DD/MM')}}</span>
              </div>
            </a-popover>
          </li>
          <li>
            <a-popover placement="right">
              <template slot="content">
                <p>This is the created date.</p>
              </template>
              <div>
                <em v-if="ticket.createdDate" class="icon ni ni-calender-date"></em>
                <span v-if="ticket.createdDate">{{$moment.utc(ticket.createdDate).local().format('H:mm DD/MM')}}</span>
              </div>
            </a-popover>
          </li>
        </ul>
    </div>
    <!-- <div class="row" style="padding-top:15px;">
      <div class="col-12">
        <span>
          <span v-for="i in activeTimers" :key="i.id">
            <i class="ni ni-circle-fill pt-1" style="color: limegreen;"/>
          </span>
        </span>
      </div>
    </div> -->
  </div>
</template>
<script>  
export default {
   computed: {
       ticketTopBarStyle: function () { 
        return {backgroundColor: this.ticket.priorityColor}
      },
       ticketStyle: function () { 
       return {borderTop: '1px solid' + this.ticket.priorityColor}
     }
   },
  props: {
    ticket: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
      ticket:function() {
          this.alertedTicket()
      },
  },
  methods: {
    completeTicket (ticket) {
       this.$http.post('/desk/Complete_Ticket/', ticket)
      .then((response) => {
        this.int_Ticket = response.data
        this.$emit('ticketSaved')
      })
      .catch(() => {
        this.$message.error('There has been an error')
      })  
    },
    // setStatus (ticket, id) {
    //   ticket.statusId = id
    //   this.updateTicketStatus(ticket)
    // },
    // updateTicketStatus(ticket) {
    //     this.$http.post('/desk/updateTicketStatus', ticket)
    //     .then(() => {
    //       this.$message.success('Status Updated and saved')
    //       this.$emit('ticketSaved')
    //       })
    //     .catch(() => {
    //       this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
    //     })
    // },
    updateTicket (id, fieldName) {
        if (fieldName === 'assignedUserId') {
            this.ticket.assignedUserId = id
        }
        this.$http.post('/desk/update_Ticket_AssignedUser/', this.ticket)
        .then(() => {
            this.$emit('ticketSaved')
            this.$message.success('The ticket has been updated')
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })  
    },
    goToUsersTickets (assignedUserId) {
        alert(assignedUserId)
    },
    ticketDoubleClicked (ticket)  {
       if(!this.clicked)
     {
         this.clicked = 1
         setTimeout(() => {
             this.clicked = null
            // single click
         }, 300);//tolerance in ms
     }else{
        this.$emit('viewTicket',ticket)
     }
    },
    openTicket (ticket)  {
      this.$emit('viewTicket',ticket)
    },
    getUsers() {
      this.$http.get('/lists/Get_Users')
        .then((response) => {
            this.users = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    alertedTicket() {
      var now = new Date()

      this.alertDate = this.$moment.utc(this.ticket.slaDueDate).local().subtract(30, 'm')
      //alert(this.alertDate)
      if (now > this.alertDate && this.ticket.finalStatus == false) {
        this.alert = true
        //alert(this.alert)
      } else {
        this.alert = false
      }
    },
    countdownTimer(){ 
      this.getTimeRemaining()
      if (this.ticket.finalStatus != true) {
        setInterval(() => {
          this.getTimeRemaining()
        }, 1000)
      }
    },
    copyTicketRefToClipboard() {
      navigator.clipboard.writeText(this.ticket.id)
      this.$message.success('Copied To Clipboard')
    },
    copyTicketSubjectToClipboard() {
      navigator.clipboard.writeText(this.ticket.title)
      this.$message.success('Copied To Clipboard')
    },
    getActiveTicketTimeAllocation() {
        this.$http.get('/desk/Get_Active_User_TimeAllocations/' + this.ticket.id)
        .then((response) => {
            this.activeTimers = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        }) 
    },
    getTimeRemaining() {
      if ( this.ticket.completedDate != null) {
        let t = Date.parse(this.$moment.utc(this.ticket.slaDueDate).local()) - Date.parse(this.$moment.utc(this.ticket.completedDate).local());
        if(t >= 0){
          var _secs = Math.floor(t / 1000 % 60) //seconds
          if (_secs < 10) {
            this.secs = '0' + _secs
          } else {
            this.secs = _secs
          }
          var _mins = Math.floor(t / 1000 / 60 % 60) //minutes
          if (_mins < 10) {
            this.mins = '0' + _mins
          } else {
            this.mins = _mins
          }
          var _hrs = Math.floor(t / (1000 * 60 * 60) % 24) //hours
          if (_hrs < 10) {
            this.hrs = '0' + _hrs
          } else {
            this.hrs = _hrs
          }
          var _days = Math.floor(t / (1000 * 60 * 60 * 24)) // days
          if (_days < 10) {
            this.days = '0' + _days
          } else {
            this.days = _days
          }
        }
        else {
          this.secs = '0' + 0
          this.mins = '0' + 0
          this.hrs = '0' + 0
          this.days = '0' + 0
        }
      } else {
        let t = Date.parse(this.$moment.utc(this.ticket.slaDueDate).local()) - Date.parse(this.$moment.utc(new Date()).local());
        if(t >= 0){
          var _secs1 = Math.floor(t / 1000 % 60) //seconds
          if (_secs1 < 10) {
            this.secs = '0' + _secs1
          } else {
            this.secs = _secs1
          }
          var _mins1 = Math.floor(t / 1000 / 60 % 60) //minutes
          if (_mins1 < 10) {
            this.mins = '0' + _mins1
          } else {
            this.mins = _mins1
          }
          var _hrs1 = Math.floor(t / (1000 * 60 * 60) % 24) //hours
          if (_hrs1 < 10) {
            this.hrs = '0' + _hrs1
          } else {
            this.hrs = _hrs1
          }
          var _days1 = Math.floor(t / (1000 * 60 * 60 * 24)) // days
          if (_days1 < 10) {
            this.days = '0' + _days1
          } else {
            this.days = _days1
          }
        } else {
          this.secs = '0' + 0
          this.mins = '0' + 0
          this.hrs = '0' + 0
          this.days = '0' + 0
        }
      }
    },
  },
  created() {
    this.getUsers()
    this.alertedTicket()
    this.countdownTimer()
    this.getActiveTicketTimeAllocation()
  },
  data () {
      return {
          clicked: null,
          tags: [],
          users: [],
          alertDate: new Date(),
          alert: false,
          secs: 0,
          mins: 0,
          hrs: 0,
          days: 0,
          activeTimers: null,
      }
  },
  // computed: { NOT SURE IF THIS IS CORRECT! It shows the correct value but updating this value is confusing me so to discuss!
  //   countdown: function() {
  //     let t = Date.parse(new Date(this.ticket.slaDueDate)) - Date.parse(new Date());
  //     let secs = null
  //     let mins = null
  //     let hrs = null
  //     if(t >= 0){
  //     secs = Math.floor(t / 1000 % 60); //seconds
  //     mins = Math.floor(t / 1000 / 60 % 60); //minutes
  //     hrs = Math.floor(t / (1000 * 60 * 60) % 24); //hours
  //     } else {
  //       secs = 0;
  //       mins = 0;
  //       hrs = 0
  //     }
  //     return hrs + ':' + mins + ':' + secs
  //   }
  // }
};
</script>
<style>
.menu {
    overflow-y: auto;
    max-height: 300px;
}
.ticketbtn-start {
 cursor:pointer;
}

.ticketbtn-start:hover {
  color:#1ee0ac;
}

.ticketbtn-pause {
 cursor:pointer;
}

.ticketbtn-pause:hover {
  color:#09c2de;
}
.ticketbtn-stop {
 cursor:pointer;
}

.ticketbtn-stop:hover {
  color:#e85347
}
.unselectable {
   -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
}
.kanban-item-error {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.75;
  }
}
</style>
