<template>
   <div v-if="boards" style="position:relative;">
        <!-- <div @mouseleave="scrollKanban(null)" @mouseover="scrollKanban('left')" style="z-index:999;position:absolute; height:100%; top:0px; left:-30px; width:30px;"></div> -->
       <div class="" >
            <div id="kanban" class="">
                <div ref="kanbanContainer" class="kanban-container" style="width: 100%;">
                    <div v-for="board in boards" v-bind:key="board.id" class="kanban-board" style="width: 320px; margin-left: 0px; margin-right: 0px; cursor:pointer;">
                        <header class="kanban-board-header kanban-light">
                            <div class="kanban-title-board">
                                <div class="kanban-title-content">
                                    <h6 class="title">{{board.status}}</h6>
                                    <span v-if="!board.tickets" class="badge badge-pill badge-outline-light text-dark">0</span>
                                    <span v-if="board.tickets" class="badge badge-pill badge-outline-light text-dark">{{board.tickets.length}}</span>
                                </div>
                                <div class="kanban-title-content">
                                    <div class="drodown">
                                         <a-dropdown>
                                            <div class="dropdown">
                                                <a class="text-soft dropdown-toggle btn btn-icon btn-trigger" >
                                                <em class="icon ni ni-more-h"></em></a>
                                            </div>
                                         <a-menu slot="overlay">
                                            <a-menu-item>
                                                <a v-on:click="addNewTicket(board.id)" href="javascript:;"><i class="bg-success-dim ni ni-edit mr-1"></i> Add Ticket</a>
                                            </a-menu-item>
                                        </a-menu>
                                        </a-dropdown>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main class="kanban-drag">
                            <draggable 
                            class="boardColumn"
                            :scroll-sensitivity="100"
                            :emptyInsertThreshold="30" 
                            v-model="board.tickets" 
                            group="tickets" 
                            @change="updateTicket($event,board.id)"  
                            :sort="false"
                            chosenClass="dg_chosen-ticket"
                            dragClass="dg_dragging-ticket"
                            ghostClass="dg_ghost-ticket"
                            >
                                <transition-group type="transition" :name="'ticketsmooth'">
                                    <ticketCard  @ticketSaved="ticketSaved"  @viewTicket="viewTicket" :ticket="ticket" v-for="(ticket) in filteredTickets(board.tickets)" v-bind:key="ticket.id">
                                    </ticketCard>
                                </transition-group>
                            </draggable>
                        </main>
                        <footer>
                            <button v-on:click="addNewTicket(board.id)" class="kanban-add-task btn btn-block"><em class="icon ni ni-plus-sm"></em><span>Add another ticket</span></button>
                        </footer>
                    </div>
                </div>
            </div>
        </div>
        <div class="kanbanBottomScroll">
            <a-slider style="margin-top:13px; width:30%;" @change="setKanbanScroll" :max="kanbanMaxScroll"  />
        </div>
         <!-- <div @mouseleave="scrollKanban(null)" @mouseover="scrollKanban('right')" style="z-index:999;position:absolute; height:100%; top:0px; right:-30px; width:30px;"></div> -->
   </div>
</template>

<script>
import draggable from 'vuedraggable'
import ticketCard from "@/components/desk/tickets/ticketcard";
export default {
  name: "Kanban",
  watch: {
    //   scrolling () {
    //       var ele = document.querySelector('.kanban-container')
    //       if (this.scrolling === 'right') {
    //            this.timer = setInterval(() => {
    //                if (!this.scrolling) {
    //                    clearInterval(this.timer)
    //                } else {
    //                    ele.scrollLeft = (ele.scrollLeft + 1)
    //                }
    //             }, 1)
    //       } else if (this.scrolling === 'left') {
    //             this.timer = setInterval(() => {
    //                if (!this.scrolling) {
    //                    clearInterval(this.timer)
    //                } else {
    //                    if (ele.scrollLeft > 0) {
    //                         ele.scrollLeft = (ele.scrollLeft - 1)
    //                    } 
    //                }
    //             }, 1)
    //       }else   {
    //          clearInterval(this.timer)
    //       }
    //   }
  },
  mounted() {
      this.setMaxScroll()
      document.getElementsByClassName("kanban-container")[0].addEventListener('scroll', this.moveWindow);
  },
  methods: {
      filteredTickets(tickets) {
            if (this.$store.state.filterSettings.filteredTicketsByTitle != '') {
              // return tickets.filter((f) =>  {  return f.title.toLowerCase().match('/^.*test.*$/') }
              //                                )
              return tickets.filter((f) => {  return (
                                                        f.title.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      || f.accountName.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      || f.contactName.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      ) })
            } else if (this.$store.state.filterSettings.filteredTicketsByStatusId) {
				return tickets.filter((f) => { return f.statusId === this.$store.state.filterSettings.filteredTicketsByStatusId })
			} else if (this.$store.state.filterSettings.filteredTicketsByPriorityId) {
				return tickets.filter((f) => { return f.priorityId === this.$store.state.filterSettings.filteredTicketsByPriorityId })
			} else if (this.$store.state.filterSettings.filteredTicketsByAssignedUserId) {
				return tickets.filter((f) => { return f.assignedUserId === this.$store.state.filterSettings.filteredTicketsByAssignedUserId })
			} else if (this.$store.state.filterSettings.filteredTicketsByActiveWorking === true) {
				return tickets.filter((f) => { return f.currentWorkingCount > 0 })
			}
			else {
                return tickets
            }
        },
      setKanbanScroll(val) {
          document.getElementsByClassName("kanban-container")[0].scrollLeft = val
      },
      moveWindow () {
          this.kanbanXposition = document.getElementsByClassName("kanban-container")[0].scrollLeft
      },
    //   whereIsScroll()  {
    //         if (this.$refs.kanbanContainer != null) {
    //           return this.$refs.kanbanContainer.scrollLeft
    //       } else {
    //        return 0
    //       }
    //   },
      testBtn () {
            alert(this.$refs.kanbanContainer.scrollLeft)
      },
    //   scrollKanban (directionStr) {
    //      this.scrolling = directionStr
    //   },
    //   onRemove () {
    //     this.nonDraggableList = this.clone(this.draggableList) // lodash shallow clone
    //     this.hideDraggableList = true
    //     this.nextTick(() => {
    //       // show the real list again
    //       this.nextTick(() => {
    //         this.hideDraggableList = false
    //       })
    //     })
    //   },
     viewTicket (ticket) {
          this.$emit('viewTicket',ticket)
     },
     viewAppointment(appointment) {
         this.$emit('viewAppointment', appointment)
     },
    addNewTicket (statusId) {
        this.$emit('addNewTicket', statusId)
    },
    addNewAppointment() {
        this.$emit('addNewAppointment')
    },
    setMaxScroll () {
       this.kanbanMaxScroll = document.getElementsByClassName("kanban-container")[0].scrollWidth  - document.getElementsByClassName("kanban-container")[0].clientWidth;
    },
    ticketSaved () {
        this.$emit('ticketSaved')
    },
    updateTicket(event,id) {
        if (event.added != null) {
            if ( event.appointmentId != null) {
                this.moveAppointment()
            } else {
                this.moveTicket(id, event.added.element.id)
            }
        } 
    },
    moveTicket (statusId, ticketId) {
        let ticket  =  {id: ticketId, statusId: statusId}
        this.$http.post('/desk/updateTicketStatus', ticket)
        .then(() => {
            this.$emit('ticketSaved')
        })
        .catch(() => {
          this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
        })
    },
  },
props: {
    boards: {
      type: Array,
      default: () => ({})
    },
  },
  components: {
    draggable,
    ticketCard,
  },
  computed: {
      // whereIsScroll()  {
      //       if (this.$refs && this.$refs.kanbanContainer) {
      //         return this.$refs.kanbanContainer.scrollLeft
      //     } else {
      //      return 0
      //     }
      // },
  },
  data() {
    return {
        kanbanMaxScroll: 0,
        kanbanXposition: 0,
        // whereIsScroll: null,
        // slideLocation: 0,
        clickTimeoutId: null,
        appointments: null
    };
  },
};
</script>
<style >
.kanbanBottomScroll {
    position: fixed;
    bottom: 0px;
    left:80px;
    width:100%;
    height:36px;
    padding-left:10px;
    padding-right:90px;
    background-color:#101924;
}
.dg_chosen-ticket{
    Background-color: #20BF55;
    color: #fff;
}
.dg_dragging-ticket{
    Background-color: #20BF55;
    color: #fff;
}
.dg_ghost-ticket{
    Background-color: #537D8D;
    color: #fff;
    opacity: 0.2;
}
.boardColumn > span {
  display: block;
  height: 100%;
  width:100%;
}

.ticketsmooth-enter-active,
.ticketsmooth-leave-active {
    transition: opacity 0.5s;
}


.ticketsmooth-enter,
.ticketsmooth-leave-active {
    opacity: 0;
}

</style>
 
