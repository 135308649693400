<template>
    <div class="ticketMenu">
        <div v-on:click="saveTicket()" class="ticketMenuItemSave"><em class="float-left icon ni ni-save"></em></div>
        <div v-on:click="changeComponent('details')" class="ticketMenuItem ticketMenuDetails" style="width:66.7%; border-right:none;"><div class="ticketMenuName"> Appointment Details</div></div>
       
        <div class="float-right">
            <div v-on:click="changeComponent('notes')" style="border-left: 1px solid #e8e8e8;" class="ticketMenuItem"><em class="float-left icon ni ni-edit"></em></div>
            <div v-on:click="changeComponent('attachments')" class="ticketMenuItem"><em class="float-left icon ni ni-clip"></em></div>
            <div v-on:click="closeAppointment()" class="ticketMenuItem"><em class="float-left icon ni ni-cross"></em></div>
        </div>
        
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        changeComponent (changeTo) {
            this.$emit('changeTabs',changeTo)
        },
        closeAppointment() {
            this.$emit('close')
        },
        saveTicket () {
         this.$emit('saveAppointment')
    },
    }
}
</script>

<style scoped>
.ticketMenuDetails {
    font-size: 16px;
    line-height: 17px;
    width:285px;
}
.ticketMenu {
    height: 31px;
    background-color: #147091;
    color: #fff;
    width:100%;
    border-bottom: 1px solid #e8e8e8;
}
.ticketMenu em {
    font-size: 20px;
}
.ticketMenu .ticketMenuItem{
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important; 
}
.ticketMenu .ticketMenuItem .ticketMenuName{
         margin-top: 3px;
}
.ticketMenu .ticketMenuItem:hover{
        background-color: #1c95c1;
}

.ticketMenu .ticketMenuItemSave{
    background-color:#1ee0ac; 
    float: left;
    padding: 5px 10px;
    border-right: 1px solid #e8e8e8;
    cursor:pointer; 
    height:30px !important;
}

.ticketMenu .ticketMenuItemSave:hover{
    background-color: #19be92;
}
</style>