<template>
    <div>
        <appointmentMenu @close="close()" @saveAppointment="save()" @changeTabs="changeTabs" />
        <div v-if="this.activeTabKey === 1" class="card-inner">
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Title:</label>
                </div>
                <div class="col-6">
                    <a-input v-model="appointment.title" class="invisibox"></a-input>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Start Date:</label>
                </div>
                <div class="col-6">
                    <a-date-picker show-time format="HH:mm a DD/MM/YY" v-model="appointment.startDate" class="invisibox"></a-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">End Date:</label>
                </div>
                <div class="col-6">
                    <a-date-picker show-time format="HH:mm a DD/MM/YY" :disabled="disableEndDate" v-model="appointment.endDate" class="invisibox"></a-date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Has No End Date:</label>
                </div>
                <div class="col-6">
                    <a-checkbox v-model="disableEndDate" class="pt-1"></a-checkbox>
                </div>
            </div>
            <hr class="hr-preview">
            <div class="col-12">
                <h6>Recurrence Settings</h6>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table">
                        <thead class="text-center">
                            <th>Mon</th>
                            <th>Tues</th>
                            <th>Weds</th>
                            <th>Thurs</th>
                            <th>Fri</th>
                            <th>Sat</th>
                            <th>Sun</th>
                        </thead>
                        <tbody class="text-center">
                            <tr>
                                <td><a-checkbox v-model="appointment.monday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.tuesday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.wednesday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.thursday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.friday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.saturday"></a-checkbox></td>
                                <td><a-checkbox v-model="appointment.sunday"></a-checkbox></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <label class="form-label float-right pt-1">Every Week:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="appointment.everyWeek" @change="disableRecurrenceBoxes()" :disabled="everyWeek" class="pt-1"></a-switch>
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Bi-Weekly:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="appointment.every2Weeks" @change="disableRecurrenceBoxes()" :disabled="every2Weeks" class="pt-1"></a-switch>
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Monthly:</label>
                </div>
                <div class="col-6">
                    <a-switch v-model="appointment.everyMonth" @change="disableRecurrenceBoxes()" :disabled="everyMonth" class="pt-1"></a-switch>
                </div>
                <div class="col-5">
                    <label class="form-label float-right pt-1">Every Week(s):</label>
                </div>
                <div class="col-6">
                    <a-input-number v-model="appointment.everyXWeeks" :min="1" @change="disableRecurrenceBoxes()" :disabled="everyXWeeks" class="invisibox"></a-input-number>
                </div>
            </div>
            <hr class="hr-preview">
            <div v-if="$hasTenantModule(1)">
                <div class="col-12">
                    <h6>Desk Settings</h6>
                </div>
                <div class="row">
                    <div class="col-5">
                        <label class="form-label float-right pt-1">Account: </label>
                    </div>
                    <div class="col-6">
                        <a-select v-model="appointment.accountId" @change="getContacts()" class="invisibox" style="width:100%">
                            <a-select-option v-for="a in accounts" :key="a.id">
                                {{ a.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <label class="form-label float-right pt-1">Contact: </label>
                    </div>
                    <div class="col-6">
                        <a-select v-model="appointment.contactId" class="invisibox" style="width:100%">
                            <a-select-option v-for="c in contacts" :key="c.id">
                                {{ c.firstName + ' ' + c.lastName }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-5">
                        <label class="form-label float-right pt-1">Status: </label>
                    </div>
                    <div class="col-6">
                        <a-select v-model="appointment.statusId" class="invisibox" style="width:100%">
                            <a-select-option v-for="s in status" :key="s.id">
                                {{ s.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="activeTabKey === 2">
            Notes
        </div>
        <div v-if="activeTabKey === 3">
            Attachments
        </div>
    </div>
    
</template>

<script>
import appointmentMenu from "@/components/appointments/appointmentMenu.vue"

export default {
    components: {
        appointmentMenu
    },
    props: {
        appointment: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            accounts: null,
            contacts: null,
            disableEndDate: false,
            everyWeek: false,
            every2Weeks: false,
            everyXWeeks: false,
            everyMonth: false,
            activeTabKey: 1,
            status: null,
        }
    },
    created() {
        this.getLists()
        this.getContacts()
    },
    methods: {
        close() {
            this.$emit('close')
        },
        changeTabs(tabName) {
            if (tabName === 'details'){ this.activeTabKey = 1}
            if (tabName === 'notes'){ this.activeTabKey = 2 }
            if (tabName === 'attachments'){ this.activeTabKey = 3 }
        },
        disableRecurrenceBoxes() {
            if(this.appointment.everyWeek == true){
                this.every2Weeks = true
                this.everyXWeeks = true
                this.everyMonth = true
                this.everyWeek = false
                this.appointment.everyXWeeks = 1
            } 
            else if ( this.appointment.every2Weeks == true) {
                this.everyWeek = true
                this.everyXWeeks = true
                this.everyMonth = true
                this.every2Weeks = false
                this.appointment.everyXWeeks = 2
            }
            else if ( this.appointment.everyMonth == true) {
                this.everyWeek = true
                this.everyXWeeks = true
                this.every2Weeks = true
                this.everyMonth = false
                this.appointment.everyXWeeks = 4
            }
            else {
                this.everyWeek = false
                this.every2Weeks = false
                this.everyMonth = false
                this.everyXWeeks = false
            }
        },
        getLists() {
            this.$http.get('/lists/Get_List/accounts')
            .then((response) => {
                this.accounts = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
            this.$http.get('/lists/Get_List/ticketStatus')
            .then((response) => {
                this.status = response.data
            })
            .catch(() => {
            this.$message.error('There has been an error')
            })
        },
        getContacts() {
            if (this.appointment.accountId) {
                this.$http.get('/accounts/Get_Account_Contacts/' + this.appointment.accountId)
                .then((response) => {
                    this.contacts = response.data
                })
                .catch(() => {
                this.$message.error('There has been an error')
                })
            }
        },
        save(){
            if (this.disableEndDate == true) {
                this.appointment.endDate = null
            }
            this.$http.post('/appointments/Add_Appointment_Schedule/', this.appointment)
            .then(() => {
                this.$message.success('Appointment Scheduled')
            })
            .catch(() => {
                this.$message.error('There has been an error')
            })
        }
    }
}
</script>

<style>

</style>