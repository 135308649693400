<template>
   <div>
        <div class="nk-block" style="margin-top:10px;">
            <div class="card card-shadow p-1 card-stretch is-compact" style="box-shadow: 0 8px 8px 0 #ccc9c9; ">
            <div class="nk-tb-list nk-tb-ulist is-compact " style=" margin-bottom:30px;">
                <div class="nk-tb-item nk-tb-head bg-dark text-white" style="font-weight: 600;">
                    <!-- <div class="nk-tb-col" style="width:5%;">
                    </div> -->
                    <div class="nk-tb-col"><span>Ticket</span></div>
                    <div class="nk-tb-col">Contract</div>
                    <div class="nk-tb-col">Account</div>
                    <div class="nk-tb-col tb-col-md"><span>Created Date</span></div>
                    <div class="nk-tb-col tb-col-md"><span>SLA Due Date</span></div>
                    <div class="nk-tb-col tb-col-sm" style="width:5%;"><span>Status</span></div>
                    <div class="nk-tb-col tb-col-md"><span>Priority</span></div>
                    <div class="nk-tb-col"><span>Assigned</span></div>
                    <div class="nk-tb-col"><span>SLA Time Remaining</span></div>
                    <div class="nk-tb-col"><span>Active Timers</span></div>
                    <!-- <div class="nk-tb-col nk-tb-col-tools">
                        <ul class="nk-tb-actions gx-1 my-n1">
                            <li>
                                <div class="drodown">
                                    <a href="#" class="dropdown-toggle btn btn-icon btn-trigger mr-n1" data-toggle="dropdown" aria-expanded="false"><em class="icon ni ni-more-h"></em></a>
                                    <div class="dropdown-menu dropdown-menu-right" style="">
                                        <ul class="link-list-opt no-bdr">
                                            <li><a href="#"><em class="icon ni ni-edit"></em><span>Update Status</span></a></li>
                                            <li><a href="#"><em class="icon ni ni-truck"></em><span>Mark as Delivered</span></a></li>
                                            <li><a href="#"><em class="icon ni ni-money"></em><span>Mark as Paid</span></a></li>
                                            <li><a href="#"><em class="icon ni ni-report-profit"></em><span>Send Invoice</span></a></li>
                                            <li><a href="#"><em class="icon ni ni-trash"></em><span>Remove Orders</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div><!-- .nk-tb-item -->
                    <template v-for="board in boards" >
                        <div style="height:40px;" class="nk-tb-item" :key="board.id">
                            <div style="position:absolute;">
                                <h5 style="margin-top:30px;margin-left:5px;width:100%;" >{{board.status}} <span style="color:rgb(135 152 173) ; font-weight:400; font-size:14px !important;" v-if="board.tickets.length <= 0"> - There are no tickets in this status</span></h5>
                            </div> 
                        </div>    
                        <ticketRow  @viewTicket="viewTicket" @ticketSaved="ticketSaved" :ticket="ticket" v-for="(ticket, index) in filteredTickets(board.tickets)" v-bind:key="ticket.id">
                            {{ index }}
                        </ticketRow>
                        <!-- <div style="padding:10px; margin-left:15px;" >
                            
                        </div> -->
                    </template> 
                </div><!-- .nk-tb-list -->
            </div>
        </div>
   </div>
</template>

<script>
import ticketRow from "@/components/desk/tickets/ticketrow";
export default {
    name: "gridview",
    methods: {
        filteredTickets(tickets) {
            if (this.$store.state.filterSettings.filteredTicketsByTitle != '') {
                             return tickets.filter((f) => {  return (
                                                        f.title.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      || f.accountName.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      || f.contactName.toLowerCase().match(this.$store.state.filterSettings.filteredTicketsByTitle.toLowerCase())
                                                      ) })
            } else if (this.$store.state.filterSettings.filteredTicketsByStatusId) {
				return tickets.filter((f) => { return f.statusId === this.$store.state.filterSettings.filteredTicketsByStatusId })
			} else if (this.$store.state.filterSettings.filteredTicketsByPriorityId) {
				return tickets.filter((f) => { return f.priorityId === this.$store.state.filterSettings.filteredTicketsByPriorityId })
			} else if (this.$store.state.filterSettings.filteredTicketsByAssignedUserId) {
				return tickets.filter((f) => { return f.assignedUserId === this.$store.state.filterSettings.filteredTicketsByAssignedUserId })
			} else if (this.$store.state.filterSettings.filteredTicketsByActiveWorking === true) {
				return tickets.filter((f) => { return f.currentWorkingCount > 0 })
			}
			else {
                return tickets
            }
        },
        viewTicket (ticket) {
            this.$emit('viewTicket',ticket)
        },
        addNewTicket (statusId) {
            this.$emit('addNewTicket',statusId)
        },
        ticketSaved () {
            this.$emit('ticketSaved')
        },
        updateTicket(event,id) {
            if (event.added != null) {
                this.moveTicket(id, event.added.element.id)
            } 
        },
        moveTicket (ticketId, statusId) {
            let ticket  =  {id: ticketId, statusId: statusId}
            this.$http.post('/support/updateTicketStatus', ticket)
            .catch(() => {
            this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
            })
        },
    },
    props: {
        boards: {
            type: Array,
            default: () => ({})
        },
    },
    components: {
        ticketRow,
    },
    computed: {
        
    },
    data() {
        return {
            clickTimeoutId: null,
            priorities: [],
            searchFilter: ''
        };
    }
};
// import ticketRow from "@/components/desk/tickets/ticketrow";
// export default {
//   name: "Kanban",
//   methods: {
//      viewTicket (ticket) {
//           this.$emit('viewTicket',ticket)
//      },
//     addNewTicket (statusId) {
//         this.$emit('addNewTicket',statusId)
//     },
//     updateTicket(event,id) {
//         if (event.added != null) {
//             this.moveTicket(id, event.added.element.id)
//         } 
//     },
//     moveTicket (ticketId, statusId) {
//         let ticket  =  {id: ticketId, statusId: statusId}
//         this.$http.post('/support/updateTicketStatus', ticket)
//         .catch(() => {
//           this.$message.error('There has been an error updating the ticket. Please refresh and try again.')
//         })
//     },
//   },
// props: {
//     boards: {
//       type: Array,
//       default: () => ({})
//     },
//   },
//   components: {
//     ticketRow,
//   },
//   computed: {
//       tickets: function () {
//       // `this` points to the vm instance
//       return this.boards[0].tickets
//     }
//   },
//   data() {
//     return {
//         clickTimeoutId: null,
//     };
//   }
// };
</script>
 
