<template>
        <div v-if="boards"> 
			<div style="position:fixed; bottom:0px;z-index: 1; border-radius: 10px 0 0 0; background-color:#101924; right:0">
				<a-dropdown v-model="visible" :placement="'topLeft'">
					<a class="dropdown-toggle btn btn-icon btn-trigger" style="color: lightgrey; margin-right: 10px;">
					<em class="icon ni ni-more-v"></em></a>
					<a-menu slot="overlay" @click="handleMenuClick">
					<a-menu-item>
						<a-checkbox v-model="environmentSettings.showTodayDeskStats" @change="updateEnvironmentSettings"> Show Today</a-checkbox>
						<a-checkbox v-model="environmentSettings.showMonthlyDeskStats" @change="updateEnvironmentSettings"> Show This Month</a-checkbox>
					</a-menu-item>
					</a-menu>
				</a-dropdown>
				<span v-if="environmentSettings.showTodayDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.ticketsOpenedToday}} Opened Today
				</span>
				<span v-if="environmentSettings.showTodayDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.ticketsClosedToday}} Closed Today
				</span>
				<span v-if="environmentSettings.showTodayDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.missedSLAToday}} Missed SLA Today
				</span>  
				<span v-if="environmentSettings.showTodayDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.ticketsOutstanding}} Outstanding
				</span> 
				<span v-if="environmentSettings.showMonthlyDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.ticketsOpenedThisMonthCount}} Opened This Month
				</span>
				<span v-if="environmentSettings.showMonthlyDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.ticketsClosedThisMonthCount}} Closed This Month
				</span>
				<span v-if="environmentSettings.showMonthlyDeskStats === true" class="" style="color: lightgrey; font-size:14px; padding: 5px; margin-right:10px;">
					{{stats.missedSLAThisMonth}} Missed SLA This Month
				</span>  
			</div>  
			<div class="nk-block-head nk-block-head-sm">
				<div class="nk-block-between">
                    <!-- <span class="badge  badge-dark text-white " style="font-size:16px; padding: 5px; margin-right:10px;">
                      {{stats.ticketsOpenedThisMonthCount}} Opened This Month
                    </span>
                    <span class="badge badge-primary badge-dim badge-outline-primary" style="font-size:16px; padding: 5px; margin-right:10px;">
                      {{stats.ticketsClosedThisMonthCount}} Closed This Month
                    </span>
                    <span class="badge badge-danger badge-dim badge-outline-danger" style="font-size:16px; padding: 5px; margin-right:10px;">
                      {{stats.missedSLAThisMonth}} Missed SLA This Month
                    </span> -->
                    <!-- <div class="card card-shadow" style="margin-right: 10px;">
                      <div class="card-inner">
                        <div class="row">
                          <div class="badge border-dark" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.ticketsOpenedToday}} <small class="currenct currency-usd">Opened Today</small>
                            </div>
                          </div>
                          <div class=" buy text-success" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.ticketsClosedToday}} <small class="currenct currency-usd">Closed Today</small>
                            </div>
                          </div>
                          <div class="text-danger border-danger" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.missedSLAToday}} <small class="currenct currency-usd">Missed SLA Today</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card card-shadow" style="margin-top: 0;">
                      <div class="card-inner">
                        <div class="row">
                          <div class=" border-dark" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.ticketsOpenedThisMonthCount}} <small class="currenct currency-usd">Opened This Month</small>
                            </div>
                          </div>
                          <div class=" buy text-success" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.ticketsClosedThisMonthCount}} <small class="currenct currency-usd">Closed This Month</small>
                            </div>
                          </div>
                          <div class=" text-danger border-danger" style="margin-right: 10px">
                            <div class="amount" style="font-size: 20px;">
                              {{stats.missedSLAThisMonth}} <small class="currenct currency-usd">Missed SLA This Month</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> -->
                <div class="nk-block-head-content" style="width:100%;">
                  <div class="card card-shadow bg-dark text-white" style="width:100%;" >
                      <div class="row">
                          <div class="col-12" style="margin:5px;">
								<div class="float-right">
									<div class="btn-group">
										<button v-on:click="viewAs ='gridview'" :class="{'active': viewAs == 'gridview'}" class="btn btn-sm btn-white btn-outline-light " ><em class="icon ni ni-view-grid"></em></button>
										<button v-on:click="viewAs ='kanban'" :class="{'active': viewAs == 'kanban'}"  class="selected btn btn-sm btn-white btn-outline-light mr-2" ><em class="icon ni ni-view-row-sq"></em></button>
									</div>
									<button v-on:click="openFilters()" class="btn btn-sm btn-light mr-2" ><i class="icon ni ni-filter"></i> Filters</button>
									<router-link :to="{ name: 'recurringtickets'}"><a-button class="btn btn-info mr-2"><em class="icon ni ni-calendar"/> Recurring Tickets</a-button></router-link>
									<button v-on:click="addNewTicket()" class="btn btn-sm btn-white btn-outline-light mr-2" ><i class="icon ni ni-plus"></i> Add Ticket</button>
								</div>
                                <a-input placeholder="Search Tickets" v-model="ticketTitleFilter" @change="setTicketTitleFilter" style="width:20%; margin-right:5px;">
                                    <a-icon slot="prefix" type="search" />
                                </a-input>
                                <a-select placeholder="Select Status" show-search option-filter-prop="children" v-model="ticketStatusFilter" @change="setTicketStatusFilter" style="width:10%; margin-right:5px;">
                                    <a-select-option :key="null">
                                        All Tickets
                                    </a-select-option>
									<a-select-option v-for="s in status" :key="s.id">
                                        {{ s.text }}
                                    </a-select-option>
                                </a-select>
                                <a-select placeholder="Select Priority" show-search option-filter-prop="children" v-model="ticketPriorityFilter" @change="setTicketPriorityFilter" style="width:10%; margin-right:5px;">
                                    <a-select-option :key="null">
                                        All Tickets
                                    </a-select-option>
									<a-select-option v-for="p in priorities" :key="p.id">
                                        {{ p.text }}
                                    </a-select-option>
                                </a-select>
                                <a-select placeholder="Select Assigned To" show-search option-filter-prop="children" v-model="ticketAssignedUserFilter" @change="setTicketAssignedUserFilter" style="width:10%; margin-right:5px;">
                                    <a-select-option :key="null">
                                        All Users
                                    </a-select-option>
									<a-select-option v-for="u in users" :key="u.id">
                                        {{ u.firstName + ' ' + u.lastName }}
                                    </a-select-option>
                                </a-select>
                                <span style="width:10%;margin-right:5px;font-weight:bold;">Show Active Working</span>
                                <a-checkbox v-model="ticketActiveWorkingFilter" @change="setTicketActiveWorkingFilter"></a-checkbox>
                          </div>   
                      </div>
                  </div>
                </div>
            </div>
          </div>
            <div v-if="ticketFilters.status" class="nk-block">
                <gridview @viewTicket="viewTicket" @addNewAppointment="addNewAppointment" @viewAppointment="viewAppointment" @addNewTicket="updateNewTicket" @ticketSaved="ticketSaved" :boards="boards" v-if="viewAs == 'gridview'"></gridview>
                <kanban @viewTicket="viewTicket" @addNewAppointment="addNewAppointment" @viewAppointment="viewAppointment" @addNewTicket="updateNewTicket" @ticketSaved="ticketSaved" :boards="boards" v-if="viewAs == 'kanban' & ticketFilters.status.length > 1"></kanban>
                <singleKanban @viewTicket="viewTicket" @addNewAppointment="addNewAppointment" @viewAppointment="viewAppointment" @addNewTicket="updateNewTicket" @ticketSaved="ticketSaved" :boards="boards" v-if="viewAs == 'kanban' & ticketFilters.status.length == 1"></singleKanban>
                <!-- <gridview v-if="viewAs == 'gridview'"></gridview> -->
            </div>  
              <a-drawer
                  :width="600"
                  placement="right"
                  :closable="false"
                  @close="closeTicket()"
                  :visible="showTicket"
                >
                <div > <!-- This was here for some reason? v-if="selectedTicket" - JP -->
                    <cptTicket  @ticketSaved="ticketSaved()" :newTicket="newTicket" :ticket="selectedTicket" :defaultStatusId="newTicketStatusId">
                      <template slot="title">
                      </template>
                    </cptTicket>

                </div>
              </a-drawer>
            <a-drawer
              title="Filters"
              :width="500"
              
              placement="right"
              :closable="true"
              @close="closeFilters()"
              :visible="showFilters"
            >
            <div style="padding:20px;">
              <!-- <div class="form-group mt-2"> This needs adding
                <label class="form-label">Search</label>
                <a-input v-model="ticketFilters.searchString"/>
              </div> -->
              <div v-if="statuses"  class="form-group">
                <label class="form-label" for="default-01">Ticket Status</label>
                <div class="form-control-wrap">
                  <multiselect v-model="ticketFilters.status" :options="statuses" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick some" label="name" track-by="name" :preselect-first="true">
                    <!-- <template slot="selection" ><span class="multiselect__single" v-if="ticketFilters.status.length &amp;&amp; !isOpen">{{ ticketFilters.status.length }} options selected</span></template> -->
                  </multiselect>
                    <!-- <a-select style="min-width: 400px;"  v-model="ticketFilters.statusId">
                      <a-select-option v-for="stat in status" v-bind:key="stat.id">
                          {{ stat.text }}
                      </a-select-option>
                    </a-select> -->
                </div>
              </div>
              <div class="form-group mt-2">
                <label class="form-label" for="default-01">Assigned User</label>
                <div class="form-control-wrap">
                    <a-select style="min-width: 400px;" show-search option-filter-prop="children" v-model="ticketFilters.assignedUserId">
                      <a-select-option :value="null" > Not selected </a-select-option> 
                      <a-select-option v-for="user in users" v-bind:key="user.id">
                          {{ user.firstName + ' ' + user.lastName }}
                      </a-select-option>
                    </a-select>
                </div>
              </div>
              <a-form-model-item label="Show Tickets Complete Between">
                <a-date-picker v-model="ticketFilters.startDate"  show-time format="DD-MM-YYYY" placeholder="Start" />
                <span><a-icon type="double-right" style="padding: 5px; fontSize:17px;"></a-icon></span>
                <a-date-picker v-model="ticketFilters.endDate"   show-time format="DD-MM-YYYY" placeholder="End" />
              </a-form-model-item>
                <button v-on:click="getBoards()" class="btn btn-block btn-dark"><i class="icon ni ni-filter"></i> Update</button>
              </div>
            </a-drawer>
            <a-drawer
              :visible="showAppointment"
              :width="500"
              :closable="false"
              @close="closeAppointment()"
              
            >
              <appointment :appointment="appointment" @close="closeAppointment()"/>
            </a-drawer>
        </div>                        
</template>

<script>
import kanban from "@/components/desk/tickets/kanban.vue";
import singleKanban from '@/components/desk/tickets/singleKanban.vue'
import gridview from "@/components/desk/tickets/gridview.vue";
import cptTicket from "@/components/desk/tickets/ticket.vue";
import appointment from "@/components/appointments/appointment.vue"
import store from '@/store'

export default {
	components: {
		kanban,
		singleKanban,
		gridview,
		cptTicket,
		appointment,
	},
	created () {
		this.getView()
		this.getEnvironmentSettings()
		this.ticketFilters.startDate = this.$moment().startOf('day')
		this.ticketFilters.endDate = this.$moment().endOf('day')
		this.getStatuses() // get default boards also.
		this.getLists()
		this.getTicketStats()
		this.registerWebSocketGroup()
		this.setRefresh()
	},
sockets: { 
    // this is signalR extension see https://mattmillican.com/blog/signalr-with-vue
    // any socket endpoints used in the application need to be added here. 
    ticketUpdated(ticket) { 
      let ticketSameStatus = false
        this.boards.forEach(board => {
            let tcktIndex  = board.tickets.findIndex(item => item.id === ticket.id);
            if (tcktIndex != -1 ) { // we have found the ticket
              if (board.tickets[tcktIndex].statusId === ticket.statusId) {// the ticket is the same statusId
                ticketSameStatus = true 
                board.tickets.splice(tcktIndex, 1, ticket) // replaces the ticket
              }  else { // ticket has moved status lets remove it from its existing one.
                board.tickets.splice(tcktIndex, 1) 
              }
            }
        }); 
        // lets account for a ticket coming back out of completed or one that
        // for some reason was not obtained in the initial load. 
        if (!ticketSameStatus){ // ticket has changed status so we have removed it lets add it appropriately.
          this.boards.forEach(board => {
            if (board.id === ticket.statusId) {
              board.tickets.push(ticket)
            }
          });
        }
    },
    ticketStatusUpdated(ticket) {  
      for (let index = 0; index < this.boards.length; index++) { 
          let tcktIndex  = this.boards[index].tickets.findIndex(item => item.id === ticket.id); 
            if (tcktIndex != -1 ) { // we have found the ticket so we need to remove it.
              this.boards[index].tickets.splice(tcktIndex,1)
            }
            if (this.boards[index].id === ticket.statusId) { // Now add it to the appropriate board 
               // add the ticket at the appropriate location.. accoring to => slaDueDate asc, priorityId asc, scheduledDate asc, createdDate asc
              let elderIdIndex  = 0
              this.boards[index].tickets.forEach((rep_ticket,idx) => { 
              // replaced a basic push to ensure positioning in the board is correct. 17/09/2021
                 // debug info below.
                 //alert( rep_ticket.id + ':' + ticket.id + ' ' + rep_ticket.slaDueDate  + ' >= ' + ticket.slaDueDate + ' result = ' + ((rep_ticket.slaDueDate === null || ticket.slaDueDate === null ) || (Date.parse(rep_ticket.slaDueDate) <= Date.parse(ticket.slaDueDate))) + ',' +
                 //rep_ticket.priorityId  + ' >= ' + ticket.priorityId + ' result  = ' + ( (rep_ticket.priorityId === null || ticket.priorityId === null ) || (rep_ticket.priorityId <= ticket.priorityId)) + ',' +
                 //rep_ticket.scheduledDate  + ' >= ' + ticket.scheduledDate + ' result = ' + ( (rep_ticket.scheduledDate === null || ticket.scheduledDate === null ) || (rep_ticket.scheduledDate <= ticket.scheduledDate)) + ',' +
                 //rep_ticket.createdDate  + ' >= ' + ticket.createdDate + ' result = ' + ( (rep_ticket.createdDate === null || ticket.createdDate === null ) || (rep_ticket.createdDate <= ticket.createdDate)))
                 if (
                     ((rep_ticket.slaDueDate === null || ticket.slaDueDate === null ) || (ticket.slaDueDate >= rep_ticket.slaDueDate)) && 
                    ( (rep_ticket.priorityId === null || ticket.priorityId === null ) || (ticket.priorityId >= rep_ticket.priorityId)) && 
                    ( (rep_ticket.scheduledDate === null || ticket.scheduledDate === null ) || (ticket.scheduledDate >= rep_ticket.scheduledDate))  && 
                    ( (rep_ticket.createdDate === null || ticket.createdDate === null ) || (ticket.createdDate >= rep_ticket.createdDate))
                    ) 
                    {
                      elderIdIndex = idx + 1
                    }
              });
              this.boards[index].tickets.splice(elderIdIndex,0,ticket)
              // below replaced with above 17/09/2021
              //this.boards[index].tickets.push(ticket)
            } 
        } 
    },
	ticketAdded(ticket) {   
		if (!this.$store.state.changes.ticketChanges.includes(ticket.changeId)) { 
			// process the record. 
			for (let index = 0; index < this.boards.length; index++) { 
			if (this.boards[index].id === ticket.statusId) { 
				let tcktIndex  = this.boards[index].tickets.findIndex(item => item.id === ticket.id);
				if (!tcktIndex || tcktIndex <= 0 || tcktIndex === undefined ) { 
				this.boards[index].tickets.push(ticket)
				}  
			} 
			}
		}
		else
		{ // this change is already applied.
		}
		}
	},
	beforeDestroy() {
		this.$socket.invoke("LeaveTenantsTicketGroup",store.state.userSettings.tenantId)  //JoinGroup is C# method name
	},
	methods: {
		setRefresh() {
            setInterval(() => {
                this.getBoards()
            }, 300000) // every 5 mins
        },
		setTicketTitleFilter() {
			this.$store.commit('setFilteredTitleTickets', this.ticketTitleFilter)
		},
		setTicketStatusFilter() {
			this.$store.commit('setFilteredStatusTickets', this.ticketStatusFilter)
		},
		setTicketPriorityFilter() {
			this.$store.commit('setFilteredPriorityTickets', this.ticketPriorityFilter)
		},
		setTicketAssignedUserFilter() {
			this.$store.commit('setFilteredAssignedUserTickets', this.ticketAssignedUserFilter)
		},
		setTicketActiveWorkingFilter() {
			this.$store.commit('setFilteredActiveWorkingTickets', this.ticketActiveWorkingFilter)
		},
		getView() {
			this.$http.get('/ViewAccess/desktickets')
			.then(() => {
			})
			.catch(() => {
			})
		},
		
		updateEnvironmentSettings() {
			this.$http.post('/settings/Update_UserEnvironment_Settings/', this.environmentSettings)
			.then(() => {
			//no response needed
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
		},
		getEnvironmentSettings() {
			this.$http.get('/settings/Get_UserEnvironment_Settings/')
			.then((response) => {
				this.environmentSettings = response.data
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
		},
		registerWebSocketGroup() {
			this.$socket.invoke("JoinTenantsTicketGroup",store.state.userSettings.tenantId)  //JoinGroup is C# method name
		},
		openFilters () {
			this.showFilters = true
		},
		closeFilters () {
			this.showFilters = false
		},
		closeTicket () {
			this.showTicket = false
			this.selectedTicket = null
		},
		addNewAppointment() {
			this.showAppointment = true
		},
		closeAppointment(){
			this.appointment = {}
			this.showAppointment = false
		},
		ticketSaved () { 
			this.getBoards()
			this.closeTicket()
			this.getTicketStats()
		},
		updateNewTicket(statusId) { 
			if (this.newTicket == false) {
			this.newTicket = true
			} else {
			this.newTicket = false
			}
			this.addNewTicket(statusId)
		},
		addNewTicket (statusId){
			this.selectedTicket  = null
			if (statusId != null) {
			this.newTicketStatusId  = statusId
			} else {
			this.newTicketStatusId  = null
			}
			this.showTicket = true
		},
		viewAppointment(appointment) {
			this.appointment = appointment
			this.showAppointment = true
		},
		viewTicket (ticket){ // requires double click
			this.newTicketStatusId = null
			this.timeoutId = null
			this.selectedTicket = null
			this.$nextTick(() => {
				this.selectedTicket = ticket
				this.showTicket = true
			})
		},
		addBoard (){
			alert('Todo: Do this.')
		},
		getBoards () {
			this.$http.post('/desk/Get_Boards/', this.ticketFilters)
			.then((response) => {
				this.boards = response.data
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
		},
		getStatuses () {
			this.$http.get('/desk/Get_TicketStatuses/')
			.then((response) => {
				this.statuses = response.data
				this.ticketFilters.status = this.statuses
				this.getBoards() 
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
		},
		getLists(){
			this.$http.get('/lists/Get_List/ticketStatus')
			.then((response) => {
				this.status = response.data
				})
			.catch(() => {
				this.$message.error('There has been an error')
			}),
			this.$http.get('/lists/Get_Users')
			.then((response) => {
				this.users = response.data
			})
			.catch(() => {
				this.$message.error('There has been an error')
			})
			this.$http.get('/lists/Get_List/ticketStatus')
			.then((response) => {
				this.status = response.data
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
			this.$http.get('/lists/Get_List/ticketPriorities')
			.then((response) => {
			this.priorities = response.data
			})
			.catch(() => {
			this.$message.error('There has been an error')
			})
		},
		getTicketStats() {
				this.$http.get("/widgets/Get_openedClosedCount/")
				.then((response) => {
				this.stats = response.data;
				})
				.catch(() => {
				this.$message.error('There has been an error')
				})
			},
		handleMenuClick() {
			this.visible = true
		}
	},
	data () {
		return {
			environmentSettings: {},
			newTicketStatusId: null,
			showAppointment: false,
			showFilters: false,
			status: [],
			users: [],
			priorities: [],
			value: null,
			isOpen: null,
			statuses: [],
			ticketFilters: {status: null, startDate: null, endDate: null, userId: null},
			clickTimeoutId: null,
			selectedTicket: null,
			showTicket: false,
			// ticket: {id: null,statusId: null},
			viewAs: 'kanban',
			newTicket: false,
			boards: null,
			stats: null,
			today: true,
			month: false,
			visible: false,
			appointment: {},
			ticketTitleFilter: '',
			ticketStatusFilter: null,
			ticketPriorityFilter: null,
			ticketAssignedUserFilter: null,
			ticketActiveWorkingFilter: null,
		}
	},
	computed: {
		// totalOutstanding: function () {
		// var outstandingCount = 0
		// console.log(this.boards[0])
		//  this.boards.forEach(element => {
		//    if (element.id != 5) {
		//      outstandingCount += element.tickets.length
		//    }
		//  })
		//  return outstandingCount;
		// }
	}
}
</script>

<style scoped>
.btn-appointment {
  background-color: #147091;
  color: white;
}
.btn-appointment:hover {
  background-color: #1c95c1;
  color: white;
}
</style>
