<template >
    <div class="nk-tb-item" v-on:click="openTicket(ticket)">
        <!-- <div class="nk-tb-col nk-tb-col-check">
            <div class=" notext">
            <em class="icon ni ni-more-v"></em>
            </div>
        </div> -->
        <div class="nk-tb-col col-auto">
            <div class="user-info">
                <span class="tb-lead" :class="{'nk-tb-item-error' : alert }"> {{ticket.title}} <span class="dot dot-success d-md-none ml-1"></span></span>
            </div>
        </div>
        <div class="nk-tb-col tb-col-md">
            <span>{{ticket.contactName}}</span>
        </div>
        <div class="nk-tb-col tb-col-md">
            <span>{{ticket.accountName}}</span>
        </div>
        <div class="nk-tb-col tb-col-lg">
            <span>{{$moment(ticket.createdDate).format('H:mm a DD/MM/YYYY')}}</span>
        </div>
        <div class="nk-tb-col tb-col-lg">
            <span>{{$moment(ticket.slaDueDate).format('H:mm a DD/MM/YYYY')}}</span>
        </div>
        
        <div class="nk-tb-col tb-col-md">
            <a-dropdown :trigger="['hover']">
                <a>{{ticket.status}}</a>
                <a-menu slot="overlay">
                    <a-menu-item v-for="s in statuses" :key="s.id" @click="updateTicketStatus(s.id)">
                        {{ s.text }}
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <div class="nk-tb-col tb-col-md">
            <a-dropdown :trigger="['hover']">
                <a :style="{color: ticket.priorityColor}">{{ticket.priority}}</a>
                <a-menu slot="overlay">
                    <a-menu-item v-for="p in priorities" :key="p.id" @click="updateTicket(p.id, 'priority')">
                        {{ p.text }}
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <div class="nk-tb-col">
            <a-dropdown :trigger="['hover']">
                <avatar  :src="ticket.assignedUserAvatarURI" style="margin:2px;" :size="36" :rounded="true" v-if="ticket.assignedUserId" :username="ticket.assignedUser" />
                <em v-else style="font-size:25px; cursor:pointer; padding-left:9px;" class="icon ni ni-user-add"></em>
                <a-menu slot="overlay" class="menu">
                    <a-menu-item v-for="u in users" :key="u.id" @click="updateTicket(u.id, 'assignedUserId')">
                        <avatar  :src="u.avatarURI" style="margin:2px;" :size="36" :rounded="true" :username="u.firstName + ' ' + u.lastName" />{{ u.firstName + ' ' + u.lastName }}
                    </a-menu-item>
                </a-menu>
            </a-dropdown>
        </div>
        <div class="nk-tb-col tb-col-lg">
            <strong :class="{'nk-tb-item-error' : alert }">{{days + ':' + hrs + ':' + mins + ':' + secs}}</strong>
        </div>
        <div class="nk-tb-col tb-col-lg">
            <a-popover v-if="activeTimers.length">
                <template slot="content">
                    <div class="row" v-for="a in activeTimers" :key="a.id">
                        <div class="col-12">
                            <span>{{ a.createdByUser }}</span>
                        </div>
                    </div>
                </template>
                <span style="color:limegreen">{{activeTimers.length}}</span>
            </a-popover>
            <span v-else>0</span>
        </div>
    </div>

                        <!-- <div class="kanban-item" :style="{borderTop: '3px solid ' + ticket.priorityColor}" v-on:click="ticketDoubleClicked(ticket)">
                        <div class="kanban-item-title">
                        <h6 class="title">{{ticket.title}}</h6>
                        <avatar v-if="ticket.assignedUser"  v-on:click="goToUsersTickets(ticket.assignedUserId)" :src="ticket.assignedUserAvatarURI" :size="25" :rounded="true" :username="ticket.assignedUser" />
                        <em v-on:click="openTicket(ticket)" v-if="!ticket.assignedUser" style="font-size:20px; cursor:pointer;" class="icon ni ni-user-add"></em>
                            </div>
                            <div class="kanban-item-text">
                                <p>{{ticket.details}}</p>
                            </div>
                            <ul class="kanban-item-tags">
                                <li v-for="tag in tags" v-bind:key="tag.id" v-bind:style="{backgroundColor: tag.color}"><span class="badge badge-info">{{tag}}</span></li>
                            </ul>
                            <div class="kanban-item-meta">
                                <ul class="kanban-item-meta-list">
                                    <li class="text-danger"><em v-if="ticket.dueDate"  class="icon ni ni-calendar"></em>
                                    <span v-if="ticket.dueDate">{{$moment(ticket.dueDate).fromNow(true)}}</span>
                                    </li>
                                </ul>
                                <ul class="kanban-item-meta-list">
                                    <li><em class="icon ni ni-clip"></em><span>{{ticket.attachmentCount}}</span></li>
                                    <li><em class="icon ni ni-comments"></em><span>{{ticket.commentCount}}</span></li>
                                </ul>
                            </div>
                        </div> -->
</template>
<script>  
export default {
  props: {
    ticket: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    goToUsersTickets (assignedUserId) {
        alert(assignedUserId)
    },
    ticketDoubleClicked (ticket)  {
       if(!this.clicked)
     {
         this.clicked = 1
         setTimeout(() => {
             this.clicked = null
            // single click
         }, 300);//tolerance in ms
     }else{
        this.$emit('viewTicket',ticket)
     }
    },
    openTicket (ticket)  {
      this.$emit('viewTicket',ticket)
    },
    getList() {
        this.$http.get('/lists/Get_List/ticketStatus')
        .then((response) => {
            this.statuses = response.data
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_List/ticketPriorities')
        .then((response) => {
          this.priorities = response.data
          })
        .catch(() => {
          this.$message.error('There has been an error')
        })
        this.$http.get('/lists/Get_Users')
        .then((response) => {
            this.users = response.data
            this.users.userName = response.data.firstName + ' ' + response.data.lastNames
        })
        .catch(() => {
        this.$message.error('There has been an error')
        })
    },
    updateTicket (id, fieldName) {
        if (fieldName === 'priority') {
            this.ticket.priorityId = id
        } else if (fieldName === 'assignedUserId') {
            this.ticket.assignedUserId = id
        }
        this.$http.post('/desk/Update_Ticket/', this.ticket)
        .then(() => {
            this.$emit('ticketSaved')
            this.$message.success('The ticket has been updated')
        })
        .catch(() => {
            this.$message.error('There has been an error')
        })  
    },
    updateTicketStatus(id) {
        this.ticket.statusId = id
        this.$http.post('/desk/updateTicketStatus/', this.ticket)
        .then(() => {
            this.$emit('ticketSaved')
            this.$message.success('The ticket has been updated')
        })
        .catch(() => {
            this.$message.error('There has been an error')
        }) 
    },
    countdownTimer(){ 
      this.getTimeRemaining()
      if (this.ticket.finalStatus != true) {
        setInterval(() => {
          this.getTimeRemaining()
        }, 1000)
      }
    },
    alertedTicket() {
      var now = new Date()

      this.alertDate = this.$moment(this.ticket.slaDueDate).subtract(30, 'm')
      //alert(this.alertDate)
      if (now > this.alertDate && this.ticket.statusId != 3 && this.ticket.statusId != 4 && this.ticket.statusId != 5) {
        this.alert = true
        //alert(this.alert)
      } else {
        this.alert = false
      }
    },
    getTimeRemaining() {
      if ( this.ticket.completedDate != null) {
        let t = Date.parse(this.$moment.utc(this.ticket.slaDueDate).local()) - Date.parse(this.$moment.utc(this.ticket.completedDate).local());
        if(t >= 0){
          var _secs = Math.floor(t / 1000 % 60) //seconds
          if (_secs < 10) {
            this.secs = '0' + _secs
          } else {
            this.secs = _secs
          }
          var _mins = Math.floor(t / 1000 / 60 % 60) //minutes
          if (_mins < 10) {
            this.mins = '0' + _mins
          } else {
            this.mins = _mins
          }
          var _hrs = Math.floor(t / (1000 * 60 * 60) % 24) //hours
          if (_hrs < 10) {
            this.hrs = '0' + _hrs
          } else {
            this.hrs = _hrs
          }
          var _days = Math.floor(t / (1000 * 60 * 60 * 24)) // days
          if (_days < 10) {
            this.days = '0' + _days
          } else {
            this.days = _days
          }
        }
        else {
          this.secs = '0' + 0
          this.mins = '0' + 0
          this.hrs = '0' + 0
          this.days = '0' + 0
        }
      } else {
        let t = Date.parse(this.$moment.utc(this.ticket.slaDueDate).local()) - Date.parse(this.$moment.utc(new Date()).local());
        if(t >= 0){
          var _secs1 = Math.floor(t / 1000 % 60) //seconds
          if (_secs1 < 10) {
            this.secs = '0' + _secs1
          } else {
            this.secs = _secs1
          }
          var _mins1 = Math.floor(t / 1000 / 60 % 60) //minutes
          if (_mins1 < 10) {
            this.mins = '0' + _mins1
          } else {
            this.mins = _mins1
          }
          var _hrs1 = Math.floor(t / (1000 * 60 * 60) % 24) //hours
          if (_hrs1 < 10) {
            this.hrs = '0' + _hrs1
          } else {
            this.hrs = _hrs1
          }
          var _days1 = Math.floor(t / (1000 * 60 * 60 * 24)) // days
          if (_days1 < 10) {
            this.days = '0' + _days1
          } else {
            this.days = _days1
          }
        } else {
          this.secs = '0' + 0
          this.mins = '0' + 0
          this.hrs = '0' + 0
          this.days = '0' + 0
        }
      }
    },
    getActiveTicketTimeAllocation() {
        this.$http.get('/desk/Get_Active_User_TimeAllocations/' + this.ticket.id)
        .then((response) => {
            this.activeTimers = response.data
        })
        .catch(() => {
            this.$message.error('There has been an error')
        }) 
    },
  },
  watch: {
      ticket:function() {
          this.alertedTicket()
      },
  },
  created() {
      this.getList()
      this.alertedTicket()
      this.countdownTimer()
      this.getActiveTicketTimeAllocation()
  },
  data () {
      return {
          clicked: null,
          statuses: [],
          priorities: [],
          users: [],
          alert: false,
          secs: 0,
          mins: 0,
          hrs: 0,
          days: 0,
          activeTimers: null,
      }
  }
};
</script>
<style scoped>
.menu {
    overflow-y: auto;
    max-height: 600px;
}

.nk-tb-item-error {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    color:rgba(180, 4, 4, 0.7);
  }
}
</style>
